<template>
  <v-container id="settings" fluid tag="section">
    <v-row>
      <v-col v-if="currentUserRole == 'admin'" cols="6">
        <v-card shaped class="fill-height py-6" elevation="1">
          <v-card-title>
            Landing Page Management
            <v-spacer></v-spacer>
            <v-btn
              @click="landingPageManagementDialog = true"
              :color="'primary'"
              large
              icon
            >
              <v-icon large> mdi-page-layout-body </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col v-if="currentUserRole == 'admin'" cols="6">
        <v-card shaped class="fill-height py-6" elevation="1">
          <v-card-title>
            Blogs Management
            <v-spacer></v-spacer>
            <v-btn
              @click="blogsManagementDialog = true"
              :color="'primary'"
              large
              icon
            >
              <v-icon large> mdi-post </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <landingPageManagementDialog
      v-if="landingPageManagementDialog"
      :dialogModel="landingPageManagementDialog"
      :selectedModel="null"
      @closeDialog="landingPageManagementDialog = false"
      @created="landingPageManagementDialog = false"
    />
    <blogsManagementDialog
      v-if="blogsManagementDialog"
      :dialogModel="blogsManagementDialog"
      :selectedModel="null"
      @closeDialog="blogsManagementDialog = false"
      @created="blogsManagementDialog = false"
    />
  </v-container>
</template>

<script>
export default {
  components: {
    landingPageManagementDialog: () =>
      import("@/components/dialogs/landingPageManagementDialog"),
    blogsManagementDialog: () =>
      import("@/components/dialogs/QuotesPortal/blogsManagementDialog"),
  },
  data: () => ({
    landingPageManagementDialog: false,
    blogsManagementDialog: false,
  }),
  computed: {},
  methods: {
    refresh() {
      // document.location.reload();
    },
  },
};
</script>
